


import Home from './components/home/home';

function App() {
  return (
    <>
    
    <div className="App">
      <Home/>
    </div>
    </>
    
  );
}

export default App;
