import React from "react";
import Navbar from "../navbar/navbar";

import { motion} from "framer-motion";
import Title from "../../images/logowhite.png";


function Home() {
  return (
    <>
      <motion.div className="home">
        <Navbar />

        <motion.div className=" pt-[25%] mobile-sm:pt-[60vh]  h-[80vh] flex items-center justify-center flex-col space-y-10">
          <motion.img className="w-[45%] 4k:w-[40%] select-none" src={Title} />
          <motion.p className="laptop:w-[60%] laptop:text-head-mob text-center  mobile-sm:text-desk mobile-sm:w-[90%] select-none">
            Once upon a time building a satellite is only a dream, a group of
            passionate innovators came together to create a firm unlike any
            other. They dreamed of a future where space exploration was more
            than just a distant dream, but a reality accessible to all. And so,
            Tospace Learn was born!
          </motion.p>
          <motion.h2 className="select-none font-spacereg mobile-sm:text-head-mob desktop:text-para 4k">
            Inspire Innovate Ignite
          </motion.h2>
         
        </motion.div>
      </motion.div>
      <div className="bg-black text-white">
        <motion.h1
          className="text-center  text-soon hover:text-[#5E4FF2] "
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
        >
          Unleashing Soon
        </motion.h1>
        <motion.a
          className="flex cursor-pointer hover:text-[#3291E8] justify-center  w-[fit-content] m-auto "
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          href="https://www.linkedin.com/company/tospace-learn/"
        >
          <motion.i
            className="fa fa-linkedin text-icon mb-[2vh]  "
            aria-hidden="true"
          ></motion.i>
          &nbsp;&nbsp;
          <p className="pt-1">ToSpace Learn</p>
        </motion.a>
      </div>
    </>
  );
}
export default Home;
