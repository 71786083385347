import React from "react";
import { motion } from "framer-motion";

import Logo from "../../images/logowhite.png"



function Navbar() {
  
  return (
    <div className="">
      <motion.div className="p-5 flex items-center justify-evenly fixed gap-x-[5vw] h-[10vh] w-[100%]"
      
      >
        <motion.div>
          <motion.img className="w-[20vh] select-none "
          initial={{ y: -60}} 
          animate={{ y: 0,}}
           transition={{  duration: 1}}
           src={Logo}
          />
            
         
        </motion.div>
        <motion.div></motion.div>
        <motion.div className=" flex gap-x-[2vw] font-space select-none  ">
          <motion.button
            className="bg-[#fff] text-black hover:text-white w-[12vh] h-[4vh] mobile-sm:w-[10vh] 4k:text-4k desktop:text-desk mobile-sm:text-mob  rounded border-none hover:border-solid border-2 border-white hover:bg-transparent  "
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ ease: "easeOut", duration: 3 }}
          >
            CART
          </motion.button>
          <motion.button
             initial={{ opacity: 0 }}
             animate={{ opacity: 1 }}
             transition={{ ease: "easeOut", duration: 3 }}
         
             
            className="w-[12vh] h-[4vh] rounded hover:bg-white hover:text-black mobile-sm:w-[10vh] mobile-sm:text-mob 4k:text-4k desktop:text-desk"
            id="menu-button" aria-expanded="true" aria-haspopup="true"
          >
            Login
          </motion.button>
        </motion.div>
      </motion.div>
      
     
    </div>
  );
}

export default Navbar;
